import { useInputEvent, type FieldConfig } from '@conform-to/react';
import React, { useId, useRef } from 'react';
import { FileUploadField as BaseFileUploadField } from './form/FileUploadField.tsx';
import { ImageUploadField as BaseImageUploadField } from './form/ImageUploadField.tsx';
import { Checkbox, type CheckboxProps } from './ui/checkbox.tsx';
import { Input } from './ui/input.tsx';
import { Label } from './ui/label.tsx';
import { Textarea } from './ui/textarea.tsx';

export type ListOfErrors = Array<string | null | undefined> | null | undefined;

export function ErrorList({
	id,
	errors,
}: {
	errors?: ListOfErrors;
	id?: string;
}) {
	const errorsToRender = errors?.filter(Boolean);
	if (!errorsToRender?.length) return null;
	return (
		<ul id={id} className="flex flex-col gap-1">
			{errorsToRender.map(e => (
				<li key={e} className="text-[10px] text-foreground-destructive">
					{e}
				</li>
			))}
		</ul>
	);
}

export function Field({
	labelProps,
	inputProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	inputProps: React.InputHTMLAttributes<HTMLInputElement>;
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const id = inputProps.id ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Input
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...inputProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

export function TextareaField({
	labelProps,
	textareaProps,
	errors,
	className,
}: {
	labelProps: React.LabelHTMLAttributes<HTMLLabelElement>;
	textareaProps: React.TextareaHTMLAttributes<HTMLTextAreaElement>;
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const id = textareaProps.id ?? textareaProps.name ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div className={className}>
			<Label htmlFor={id} {...labelProps} />
			<Textarea
				id={id}
				aria-invalid={errorId ? true : undefined}
				aria-describedby={errorId}
				{...textareaProps}
			/>
			<div className="min-h-[32px] px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

export function CheckboxField({
	labelProps,
	buttonProps,
	errors,
	className,
}: {
	labelProps: JSX.IntrinsicElements['label'];
	buttonProps: CheckboxProps;
	errors?: ListOfErrors;
	className?: string;
}) {
	const fallbackId = useId();
	const buttonRef = useRef<HTMLButtonElement>(null);
	// To emulate native events that Conform listen to:
	// See https://conform.guide/integrations
	const control = useInputEvent({
		// Retrieve the checkbox element by name instead as Radix does not expose the internal checkbox element
		// See https://github.com/radix-ui/primitives/discussions/874
		ref: () =>
			buttonRef.current?.form?.elements.namedItem(buttonProps.name ?? ''),
		onFocus: () => buttonRef.current?.focus(),
	});
	const id = buttonProps.id ?? buttonProps.name ?? fallbackId;
	const errorId = errors?.length ? `${id}-error` : undefined;
	return (
		<div className={className}>
			<div className="flex gap-2">
				<Checkbox
					id={id}
					ref={buttonRef}
					aria-invalid={errorId ? true : undefined}
					aria-describedby={errorId}
					{...buttonProps}
					onCheckedChange={state => {
						control.change(Boolean(state.valueOf()));
						buttonProps.onCheckedChange?.(state);
					}}
					onFocus={event => {
						control.focus();
						buttonProps.onFocus?.(event);
					}}
					onBlur={event => {
						control.blur();
						buttonProps.onBlur?.(event);
					}}
					type="button"
				/>
				<label
					htmlFor={id}
					{...labelProps}
					className="self-center text-body-xs text-muted-foreground"
				/>
			</div>
			<div className="px-4 pb-3 pt-1">
				{errorId ? <ErrorList id={errorId} errors={errors} /> : null}
			</div>
		</div>
	);
}

type InputFieldProps<X> = {
	className?: string;
	field: FieldConfig<X>;
	label: React.ReactNode;
	readOnly?: boolean;
	autoFocus?: boolean;
	disabled?: boolean;
	tabIndex?: number;
};

export function FileUploadField(
	props: InputFieldProps<File | undefined> & {
		acceptedFileTypes: string[];
	},
) {
	const { field, label, acceptedFileTypes } = props;
	const fallbackId = useId();
	const id = field.name ?? fallbackId;
	const errorId = field.errors?.length ? `${id}-error` : undefined;

	return (
		<>
			<BaseFileUploadField
				name={field.name}
				label={label}
				defaultValue={
					undefined /* field.defaultValue as unknown as FileEntity */
				}
				multiple={false}
				acceptedFileTypes={acceptedFileTypes}
				maxFiles={1}
			/>
			<ErrorList errors={field.errors} id={errorId} />
		</>
	);
}

export function MultipleFileUploadField(
	props: InputFieldProps<string[] | undefined> & {
		acceptedFileTypes: string[];
		maxFiles?: number;
	},
) {
	const { field, label, maxFiles, acceptedFileTypes } = props;
	const fallbackId = useId();
	const id = field.name ?? fallbackId;
	const errorId = field.errors?.length ? `${id}-error` : undefined;

	return (
		<>
			<BaseFileUploadField
				name={field.name}
				label={label}
				defaultValue={
					undefined /* field.defaultValue as unknown as FileEntity[] */
				}
				multiple={true}
				acceptedFileTypes={acceptedFileTypes}
				maxFiles={maxFiles}
			/>
			<ErrorList errors={field.errors} id={errorId} />
		</>
	);
}

export function ImageUploadField(
	props: InputFieldProps<string | string[] | undefined> & {
		maxFiles?: number;
		serverPath?: string;
	},
) {
	const { field, label, serverPath, maxFiles } = props;
	const fallbackId = useId();
	const id = field.name ?? fallbackId;
	const errorId = field.errors?.length ? `${id}-error` : undefined;

	return (
		<>
			<BaseImageUploadField
				name={field.name}
				label={label}
				maxFiles={maxFiles}
				defaultValue={undefined /* field.defaultValue as unknown as Image */}
				serverPath={serverPath}
			/>
			<ErrorList errors={field.errors} id={errorId} />
		</>
	);
}
