import { type FilePondInitialFile } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginValidateImageSize from 'filepond-plugin-image-validate-size';
import { FilePond, registerPlugin } from 'react-filepond';
import "filepond/dist/filepond.min.css?__remix_sideEffect__";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css?__remix_sideEffect__";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  // FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginValidateImageSize
);

export function ImageUploadField(props: {
  name: string;
  label: React.ReactNode;
  aspectRatio?: string;
  defaultValue?:
  {fileId?: string;id?: string;} |
  {fileId?: string;id?: string;}[];
  maxFiles?: number;
  serverPath?: string;
}) {
  const {
    name,
    label,
    maxFiles = 1,
    aspectRatio,
    defaultValue,
    serverPath = '/resources/file/'
  } = props;

  const files = defaultValue ?
  Array.isArray(defaultValue) ? (
  defaultValue.map((image) => ({
    source: `${serverPath}${image.fileId ? image.fileId : image.id}`,
    options: { type: 'local' }
  })) as FilePondInitialFile[]) : (
  [
  {
    source: `${serverPath}${
    defaultValue?.fileId ? defaultValue?.fileId : defaultValue?.id
    }`,
    options: { type: 'local' }
  }] as
  FilePondInitialFile[]) :
  undefined;

  return (
    <>
			<div>{label}</div>
			<FilePond
        className={maxFiles > 1 ? 'filepond-grid' : ''}
        files={files}
        allowMultiple={maxFiles > 1}
        // file size validation
        allowFileSizeValidation={true}
        maxFileSize="5MB"
        // file type validation
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/*']}
        // image crop
        allowImageCrop={!!aspectRatio}
        imageCropAspectRatio={aspectRatio}
        // image size validation
        allowImageValidateSize={true}
        imageValidateSizeMinWidth={200}
        imageValidateSizeMinHeight={200}
        imageValidateSizeMaxWidth={2500}
        imageValidateSizeMaxHeight={2500}
        // allowImageResize={true}
        /* imageResizeTargetHeight={500}
        imageResizeTargetWidth={200}
        imageResizeMode="force"
        imageResizeUpscale={true} */
        allowImageExifOrientation={true}
        maxFiles={maxFiles}
        storeAsFile={false}
        name={name}
        // name={name} /* sets the file input name, it's filepond by default */
        labelIdle='Bild per Drag & Drop hinzufügen oder <span class="filepond--label-action">durchsuchen</span>'
        credits={false}
        server={{
          url: 'http://localhost:3000/resources',
          process: {
            url: '/upload',
            onload: (response) => {
              console.log('onload', response);
              return response;
            }
          },
          revert: '/upload',
          restore: '/upload',
          load: '/upload',
          fetch: '/upload'
          /* process: {
          	url: "",
          	onload: (response) => {
          		console.log("onload", response);
          		setValue("favicon2", response.key);
          		return response;
          	},
          	onerror: (response) => {
          		console.log("error", response);
          		return response.data;
          	},
          	ondata: (formData) => {
          		return formData;
          	},
          }, */
        }} />

		</>);

}