import { type FilePondInitialFile } from 'filepond';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { FilePond, registerPlugin } from 'react-filepond';
import "filepond/dist/filepond.min.css?__remix_sideEffect__";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css?__remix_sideEffect__";

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

type CommonProps = {
  name: string;
  label: React.ReactNode;
  maxFiles?: number;
  acceptedFileTypes?: string[];
};

type ConditionalProps =
{
  multiple: true;
  defaultValue?: File[];
} |
{
  multiple?: false;
  defaultValue?: File;
};

export function FileUploadField(props: CommonProps & ConditionalProps) {
  const {
    name,
    label,
    multiple = false,
    maxFiles = 5,
    defaultValue,
    acceptedFileTypes
  } = props;

  console.log(defaultValue);
  let files = ([] as FilePondInitialFile[]);
  /* if (Array.isArray(defaultValue)) {
  	files = defaultValue
  		? defaultValue.map(file => ({
  				source: `/resources/file2/${file?.id}`,
  				options: { type: 'local' },
  		  }))
  		: [];
  } else if (defaultValue) {
  	files = defaultValue
  		? [
  				{
  					source: `/resources/file/${defaultValue?.id}`,
  					options: { type: 'local' },
  				},
  		  ]
  		: [];
  } */

  return (
    <>
			<div>{label}</div>
			<FilePond
        files={files}
        allowMultiple={multiple}
        // file size validation
        allowFileSizeValidation={true}
        maxFileSize="10MB"
        // file type validation
        allowFileTypeValidation={true}
        acceptedFileTypes={acceptedFileTypes}
        maxFiles={multiple ? maxFiles : 1}
        storeAsFile={true}
        name={name} /* sets the file input name, it's filepond by default */
        labelIdle='Datei(en) per Drag & Drop hinzufügen oder <span class="filepond--label-action">durchsuchen</span>'
        credits={false}
        // upload config
        server={{
          url: '/resources/upload'
        }}
        instantUpload={true}
        chunkUploads={true}
        chunkForce={false}
        chunkSize={Number.parseInt(ENV.UPLOAD_CHUNK_SIZE)}
        onupdatefiles={(files) => {
          console.log('filepond update files', files);
        }} />

		</>);

}